﻿/// <reference path="../@types/alertify/index.d.ts" />
/// <reference path="../@types/saop_core/saopCommonServices.d.ts" />
/// <reference path="../@types/saop_core/saopCoreServices.d.ts" />
/// <reference path="../@types/saop_core/saopTranslationsServices.d.ts" />
import * as SaopViewFormServices from "../@types/saop_core/saopViewFormServices"
import { SaopChangePwdView } from '../Account/saop-change-pwd-view'

export class SaopSettingsView { 
    //
    private _saopServicesCore : icSaopCore.SaopServicesCoreEx;
    private _saopChangePwdView : SaopChangePwdView;

    constructor(viewId:string,libName:string = "") {
        
        this._saopServicesCore = new icSaopCore.SaopServicesCoreEx(); 
        this._saopChangePwdView = new SaopChangePwdView(viewId,libName);
    }

    initView(): void {
        icSaopCore.global.ConsoleLogDegug("SaopSettingsView.initView");

        this.bindViewEvents();   
        
        this.checkPasswordStrength();
        
    }

    bindViewEvents(){
        icSaopCore.global.ConsoleLogDegug("SaopSettingsView.bindGridEvents");
        let _this = this;
        //
        $("#selectJezik").off("change");
        $("#selectJezik").on('change', function (row) {
            _this.vrstaJezikaChanged();
        });  
        
        this._saopChangePwdView.bindViewEvents();      
    }

    vrstaJezikaChanged(): void {
        icSaopCore.global.ConsoleLogDegug("SaopSettingsView.vrstaJezikaChanged");
        let _this = this;

        let vrstaJezikaElement = document.getElementById('selectJezik') as HTMLSelectElement;
        let jezik = vrstaJezikaElement.options[vrstaJezikaElement.selectedIndex].value;

        let idWebOperaterja = (document.getElementById('IDWebOperaterja') as HTMLInputElement).value;

        let data = { idweboperaterja: idWebOperaterja, jezik: jezik};

        this._saopServicesCore.afterDone = function (response:SaopViewFormServices.SaopWfSubmitResult) {
            if (response.alertMessage) {
                alertify.message(response.alertMessage);
            }

            if (response.viewContent) {
                $("#messageBanner").html(response.viewContent);
            }

            if (response.success) {
                //reload current page 
                icSaopCore.global.relodeCurrentPage();
            }
            else {
                $("#selectJezik").val(response.jezik);
            }
        };
        this._saopServicesCore.ExecuteAction({
            action: "Settings/UpdateSaopwebOperaterjiJezik"
            , data: data
            , type: "POST"
            , spinnerDivId: "#absenceConfirmationPendingLoading"
        });        
    }

    public checkPasswordStrength():void{
        this._saopChangePwdView.checkPasswordStrength();
    }

}


